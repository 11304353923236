/**/
/* Bootstrap reset */
/**/

.sky-form label,
.sky-form .label {
    border-radius: 0;
    font-size: 100%;
    text-align: left;
    white-space: normal;
    color: inherit;
    margin-bottom: 0;
    font-weight: inherit;
    padding: 0.2rem 0 0.3rem;
}
.sky-form .radio,
.sky-form .checkbox {
    font-weight: inherit;
}
.sky-form .radio + .radio,
.sky-form .checkbox + .checkbox {
  margin-top: 0;
}

/**/
/* defaults */
/**/
.sky-form {
    margin: 0;
    outline: none;
    font-size: 1.3rem;
    line-height: 1.55rem;
    border: 1px solid #eee;
}
.sky-form header {
    display: block;
    padding: 20px 30px;
    border-bottom: 1px solid #eee;
    font-size: 2.0rem;
    line-height: 2.5rem;
    font-weight: normal;
}
.sky-form fieldset {
    display: block;
    padding: 25px 30px 5px;
    border: none;
    background: rgba(255,255,255,.9);
}
.sky-form  .webform_subfields fieldset {
    padding-top: 0;
    padding-bottom: 0;
}
.sky-form fieldset + fieldset {
    border-top: 1px solid #eee;
}
.sky-form section {
    margin-bottom: 20px;
}
.sky-form footer {
    display: block;
    padding: 15px 30px 25px;
    border-top: 1px solid #eee;
}
.sky-form footer:after {
    content: '';
    display: table;
    clear: both;
}
.sky-form .label {
    display: block;
    margin-bottom: 6px;
    line-height: 1.9rem;
    font-weight: 400;
}
.sky-form .label.col {
    margin: 0;
    padding-top: 10px;
}
.sky-form .note {
    margin-top: 6px;
    padding: 0 1px;
    font-size: 100%;
    line-height: 1.5;
}
.sky-form .input,
.sky-form .select,
.sky-form .textarea,
.sky-form .radio,
.sky-form .checkbox,
.sky-form .toggle,
.sky-form .button {
    position: relative;
    display: block;
}
.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 39px;
    padding: 6px 10px;
    outline: none;
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
    background: #fff;
    font-size: 1.5rem;
    line-height: 2.3rem;
    color: #404040;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.sky-form .input input::placeholder,
.sky-form .select select::placeholder,
.sky-form .textarea textarea::placeholder {
    color: #999;
}

.sky-form .progress {
    float: right;
    margin-top: 10px;
    line-height: 3.9rem;
    color: #232323;
}
.sky-form button::-moz-focus-inner {
    padding: 0;
    border: 0;
}


/**/
/* captcha inputs */
/**/
.sky-form .input-captcha img {
    position: absolute;
    top: 2px;
    right: 2px;
    border-left: 1px solid #e5e5e5;
}


/**/
/* file inputs */
/**/
.sky-form .input-file .button {
    position: absolute;
    top: 4px;
    right: 4px;
    float: none;
    height: 31px;
    margin: 0;
    padding: 0 20px;
    font-size: 1.3rem;
    line-height: 3.1rem;
}

.sky-form .input-file .button input {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    font-size: 3.0rem;
    cursor: pointer;
    opacity: 0;
}


/**/
/* selects */
/**/
.sky-form .select i {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 28px;
    height: 35px;
    background: #fff;
    pointer-events: none;
}
.sky-form .select i:after,
.sky-form .select i:before {
    content: '';
    position: absolute;
    right: 10px;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}
.sky-form .select i:after {
    bottom: 11px;
    border-top: 5px solid #5f5f5f;
}
.sky-form .select i:before {
    top: 11px;
    border-bottom: 5px solid #5f5f5f;
}
.sky-form .select select {
    padding-right: 28px;
}
.sky-form .select-multiple select {
    height: auto;
}


/**/
/* textareas */
/**/
.sky-form .textarea textarea {
    height: auto;
    resize: none;
}
.sky-form .textarea-resizable textarea {
    resize: vertical;
}
.sky-form .textarea-expandable textarea {
    height: 39px;
}
.sky-form .textarea-expandable textarea:focus {
    height: auto;
}


/**/
/* radios and checkboxes */
/**/
.sky-form .radio,
.sky-form .checkbox {
    margin-bottom: 4px;
    padding-left: 27px;
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #5f5f5f;
    cursor: pointer;
}
.sky-form .radio:last-child,
.sky-form .checkbox:last-child {
    margin-bottom: 0;
}
.sky-form .radio input,
.sky-form .checkbox input {
    position: absolute;
    left: -9999px;
}
.sky-form .radio i,
.sky-form .checkbox i {
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
    width: 17px;
    height: 17px;
    outline: none;
    border-width: 1px;
    border-style: solid;
    background: #fff;
}
.sky-form .radio i {
    border-radius: 50%;
}
.sky-form .radio input + i:after,
.sky-form .checkbox input + i:after {
    position: absolute;
    opacity: 0;
    transition: opacity 0.1s;
}
.sky-form .radio input + i:after {
    content: '';
    top: 3px;
    left: 3px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
}
.sky-form .checkbox input + i:after {
    content: '\f00c';
    top: -5px;
    left: -1px;
    font-size: 21px;
    line-height: 21px;
    font-family: FontAwesome;
    font-weight: normal;
    text-align: center;
}
.sky-form .radio input:checked + i:after,
.sky-form .checkbox input:checked + i:after {
    opacity: 1;
}
.sky-form .inline-group {
    margin: 0 -30px -4px 0;
}
.sky-form .inline-group:after {
    content: '';
    display: table;
    clear: both;
}
.sky-form .inline-group .radio,
.sky-form .inline-group .checkbox {
    float: left;
    margin-right: 30px;
    margin-top: 10px;
}
.sky-form .inline-group .radio:last-child,
.sky-form .inline-group .checkbox:last-child {
    margin-bottom: 4px;
}


/**/
/* toggles */
/**/
.sky-form .toggle {
    margin-bottom: 4px;
    padding-right: 61px;
    font-size: 15px;
    line-height: 2.7rem;
    color: #5f5f5f;
    cursor: pointer;
}
.sky-form .toggle:last-child {
    margin-bottom: 0;
}
.sky-form .toggle input {
    position: absolute;
    left: -9999px;
}
.sky-form .toggle i {
    content: '';
    position: absolute;
    top: 6px;
    right: 0;
    display: block;
    width: 49px;
    height: 17px;
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    background: #fff;
}
.sky-form .toggle i:after {
    content: 'OFF';
    position: absolute;
    top: 1px;
    right: 8px;
    left: 8px;
    font-style: normal;
    font-size: 9px;
    line-height: 13px;
    font-weight: 700;
    text-align: left;
    color: #5f5f5f;
}
.sky-form .toggle i:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 3px;
    right: 4px;
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    opacity: 1;
    transition: right 0.2s;
    background-color: #5f5f5f;
}
.sky-form .toggle input:checked + i:after {
    content: 'ON';
    text-align: right;
    color: #404040;
}
.sky-form .toggle input:checked + i:before {
    right: 36px;
    background-color: #404040;
}


/**/
/* ratings */
/**/
.sky-form .rating {
    margin-bottom: 4px;
    font-size: 1.5rem;
    line-height: 2.7rem;
    color: #404040;
}
.sky-form .rating:last-child {
    margin-bottom: 0;
}
.sky-form .rating input {
    position: absolute;
    left: -9999px;
}
.sky-form .rating label {
    display: block;
    float: right;
    height: 17px;
    margin-top: 5px;
    padding: 0 2px;
    font-size: 1.7rem;
    line-height: 1.7rem;
    cursor: pointer;
}


/**/
/* buttons */
/**/
.sky-form .button {
    float: right;
    height: 39px;
    overflow: hidden;
    margin: 10px 0 0 20px;
    padding: 0 25px;
    outline: none;
    border: 0;
    font-size: 1.5rem;
    line-height: 3.9rem;
    font-weight: 300;
    text-decoration: none;
    cursor: pointer;
}
.sky-form .button-uploading {
    position: relative;
    color: transparent;
    cursor: default;
}
.sky-form .button-uploading:after {
    content: 'Uploading...';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    -o-animation: blink 1s linear infinite;
    -ms-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    -webkit-animation: blink 1s linear infinite;
}
@-o-keyframes blink
{
  0% {opacity: 1}
  50% {opacity: 0.3}
  100% {opacity: 1}
}
@-ms-keyframes blink
{
  0% {opacity: 1}
  50% {opacity: 0.3}
  100% {opacity: 1}
}
@-moz-keyframes blink
{
  0% {opacity: 1}
  50% {opacity: 0.3}
  100% {opacity: 1}
}
@-webkit-keyframes blink
{
  0% {opacity: 1}
  50% {opacity: 0.3}
  100% {opacity: 1}
}


/**/
/* icons */
/**/
.sky-form .icon-append,
.sky-form .icon-prepend {
    position: absolute;
    top: 5px;
    width: 29px;
    height: 29px;
    font-size: 1.5rem;
    line-height: 2.9rem;
    text-align: center;
}
.sky-form .icon-append {
    right: 5px;
    padding-left: 3px;
    border-left-width: 1px;
    border-left-style: solid;
}
.sky-form .icon-prepend {
    left: 5px;
    padding-right: 3px;
    border-right-width: 1px;
    border-right-style: solid;
}
.sky-form .input .icon-prepend + input,
.sky-form .textarea .icon-prepend + textarea {
    padding-left: 46px;
}
.sky-form .input .icon-append + input,
.sky-form .textarea .icon-append + textarea {
    padding-right: 46px;
}
.sky-form .input .icon-prepend + .icon-append + input,
.sky-form .textarea .icon-prepend + .icon-append + textarea {
    padding-left: 46px;
}


/**/
/* grid */
/**/
.sky-form .row {
    margin: 0 -15px;
}
.sky-form .row:after {
    content: '';
    display: table;
    clear: both;
}
.sky-form .col {
    float: left;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
}
.sky-form .col-1 {
    width: 8.33%;
}
.sky-form .col-2 {
    width: 16.66%;
}
.sky-form .col-3 {
    width: 25%;
}
.sky-form .col-4 {
    width: 33.33%;
}
.sky-form .col-5 {
    width: 41.66%;
}
.sky-form .col-6 {
    width: 50%;
}
.sky-form .col-7 {
    width: 58.33%;
}
.sky-form .col-8 {
    width: 66.67%;
}
.sky-form .col-9 {
    width: 75%;
}
.sky-form .col-10 {
    width: 83.33%;
}
.sky-form .col-11 {
    width: 91.66%;
}
@media screen and (max-width: 600px) {
    .sky-form .col {
        float: none;
        width: 100%;
    }
}


/**/
/* normal state */
/**/
.sky-form .input input,
.sky-form .select select,
.sky-form .textarea textarea,
.sky-form .radio i,
.sky-form .checkbox i,
.sky-form .toggle i,
.sky-form .icon-append,
.sky-form .icon-prepend {
    border-color: #999;
    transition: border-color 0.3s;
}
.sky-form .rating label {
    color: #ccc;
    transition: color 0.3s;
}
.sky-form .button {
    opacity: 0.8;
    transition: opacity 0.2s;
}
.sky-form .button.button-secondary {
    background-color: #b3b3b3;
}
.sky-form .icon-append,
.sky-form .icon-prepend {
    color: #ccc;
}

/**/
/* checked state */
/**/
.sky-form .radio input + i:after {
    background-color: #404040;
}
.sky-form .checkbox input + i:after {
    color: #404040;
}
.sky-form .radio input:checked + i,
.sky-form .checkbox input:checked + i,
.sky-form .toggle input:checked + i {
    border-color: #404040;
}
.sky-form .rating input:checked ~ label {
    color: #404040;
}


/**/
/* error state */
/**/
.sky-form .state-error input,
.sky-form .state-error select,
.sky-form .state-error select + i,
.sky-form .state-error textarea,
.sky-form .radio.state-error i,
.sky-form .checkbox.state-error i,
.sky-form .toggle.state-error i,
.sky-form .toggle.state-error input:checked + i {
    background: #fff0f0;
}
.sky-form .state-error + em {
    display: block;
    margin-top: 6px;
    padding: 0 1px;
    font-style: normal;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: #ee9393;
}
.sky-form .rating.state-error + em {
    margin-top: -4px;
    margin-bottom: 4px;
}


/**/
/* success state */
/**/
.sky-form .state-success input,
.sky-form .state-success select,
.sky-form .state-success select + i,
.sky-form .state-success textarea,
.sky-form .radio.state-success i,
.sky-form .checkbox.state-success i,
.sky-form .toggle.state-success i,
.sky-form .toggle.state-success input:checked + i {
    background: #f0fff0;
}
.sky-form .state-success + em {
    display: block;
    margin-top: 6px;
    padding: 0 1px;
    font-style: normal;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: #ee9393;
}
.sky-form .note-success {
    color: #6fb679;
}


/**/
/* disabled state */
/**/
.sky-form .input.state-disabled input,
.sky-form .select.state-disabled,
.sky-form .textarea.state-disabled,
.sky-form .radio.state-disabled,
.sky-form .checkbox.state-disabled,
.sky-form .toggle.state-disabled,
.sky-form .button.state-disabled {
    cursor: default;
    opacity: 0.5;
}

/**/
/* submited state */
/**/
.sky-form .message {
    display: none;
    color: #6fb679;
}
.sky-form .message i {
    display: block;
    margin: 0 auto 20px;
    width: 81px;
    height: 81px;
    border: 1px solid #6fb679;
    border-radius: 50%;
    font-size: 3.0rem;
    line-height: 8.1rem;
}
.sky-form.submited fieldset,
.sky-form.submited footer {
    display: none;
}
.sky-form.submited .message {
    display: block;
    padding: 25px 30px;
    background: rgba(255,255,255,.9);
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 2.7rem;
    text-align: center;
}


